@use "../../../ui/vars" as *;

.pagination {
  align-items: center;
  display: flex;
  gap: 1rem;
  justify-content: center;
  padding: 1rem 0;

  &-label {
    font-size: var(--type-smaller-font-size);
  }
}

@use "../../../ui/vars" as *;

$text-stroke-width: 2px;

.offer-hero {
  background-color: var(--background-primary);
  color: var(--text-primary);
  display: grid;
  grid-template-rows: fit-content(24rem);
  width: 100%;

  .offer-hero-header {
    align-items: center;

    color: var(--text-secondary);
    display: flex;
    flex-direction: row;
    grid-column: 1;
    grid-row: 1;
    margin: 0 auto;
    max-width: 52rem;
    padding: 1rem;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    width: initial;

    .offer-hero-wrapper {
      align-self: start;
      z-index: 1;

      h1 {
        font-size: 3rem;
        margin-top: 1rem;

        span {
          display: block;
        }

        span:first-child {
          margin-bottom: 0.5rem;
        }
      }

      h2 {
        font: var(--type-heading-h3);
        width: 100%;
      }

      .button-wrapper {
        display: flex;
        gap: 1rem;
        width: 100%;

        a {
          border-radius: var(--elem-radius);
          font-size: 14px;
          font-weight: 600;
          line-height: 175%;
          padding: 0.5rem;
          position: relative;
          text-align: center;
          -moz-transition: all 0.2s ease;
          transition: all 0.2s ease;
          width: 140px;
          width: 100%;
          z-index: 1;
        }

        .button-primary {
          background-color: var(--text-primary);
          border: 2px solid var(--text-primary);
          color: var(--text-invert);

          &:hover {
            background-color: var(--text-secondary);
            border-color: var(--text-secondary);
          }
        }

        .button-secondary {
          background: #15141a1a;
          border: 2px solid var(--button-secondary-border-red);
          color: #fff;
        }

        .button-secondary:hover {
          border: 2px solid var(--button-secondary-border-red-focus);
        }
      }
    }
  }

  .mandala-wrapper {
    --mandala-primary: var(--border-primary);
    grid-column: 1;
    grid-row: 1;
    overflow: hidden;
    top: 0;
    -moz-transition: all 1s ease-in-out;
    transition: all 1s ease-in-out;
    width: 100%;

    .mandala-translate {
      transform: translate(10rem, -8rem) scale(1.2);
      @media (min-width: $screen-sm) {
        transform: translate(12rem, -8rem);
      }
    }
  }
}

@use "../ui/vars" as *;

// .with sidebar is needed to
.curriculum-content-container.curriculum-overview,
.curriculum-content-container {
  .curriculum-content {
    .modules {
      input[type="radio"]:not(:checked) ~ a.lets-begin,
      input[type="radio"]:not(:checked) ~ ol.modules-list {
        display: none;
      }
    }

    ol.modules-list-list {
      display: grid;
      grid-template-areas:
        "started core  extensions"
        "hr      hr    hr"
        "mod     mod   mod"
        "cta     cta   cta";

      grid-template-columns: auto;
      margin: 0;
      padding: 0;

      @media (min-width: $screen-sm) {
        grid-template-areas:
          "started core  extensions spacer"
          "hr      hr    hr         hr"
          "mod     mod   mod        mod"
          "cta     cta   cta        cta";

        grid-template-columns: auto auto auto 1fr;
      }

      &::before {
        border: none;
        border-top: 1px solid var(--text-inactive);
        content: "";
        grid-area: hr;
        margin: 0 0 1.5rem;
        width: 100%;
      }

      li.modules-list-list-item {
        display: contents;

        > input:checked + label {
          color: var(--text-primary);

          &::before {
            height: 0;
            position: absolute;
            transform: translate3d(-0.75rem, 0.75rem, 0);
            width: 0;
          }
        }

        > input:checked:focus-visible + label {
          outline-color: var(--accent-primary);
          outline-offset: 1px;
          outline-style: auto;
        }

        > input:not(:checked) + label {
          color: var(--text-secondary);
          opacity: 0.775;
        }

        > label {
          cursor: pointer;
          width: max-content;
        }

        &#modules-0 {
          > label,
          > input {
            grid-area: started;
          }

          > input:checked + label::before {
            content: url("../assets/icons/curriculum-modules-underline.svg#1");
          }
        }

        &#modules-1 {
          > label,
          > input {
            grid-area: core;

            @media (min-width: $screen-sm) {
              margin-left: 2rem;
            }
          }

          > input:checked + label::before {
            content: url("../assets/icons/curriculum-modules-underline.svg#2");
          }
        }

        &#modules-2 {
          > label,
          > input {
            grid-area: extensions;

            @media (min-width: $screen-sm) {
              margin-left: 2rem;
            }
          }

          > input:checked + label::before {
            content: url("../assets/icons/curriculum-modules-underline.svg#3");
          }
        }

        > ol.modules-list {
          grid-area: mod;
          margin: 0;
        }

        > a.lets-begin {
          grid-area: cta;
          margin-left: 0.5rem;
          margin-top: 2rem;
          width: fit-content;

          @media (min-width: $screen-md) {
            margin-left: 0;
          }
        }
      }
    }

    ol.modules-list {
      display: grid;
      flex-wrap: wrap;
      gap: 1rem;
      grid-template-columns: 1fr 1fr 1fr;
      margin: 0;
      overflow: scroll;
      padding: 0.5rem;
      scroll-snap-type: inline mandatory;

      @media (min-width: $screen-md) {
        overflow: inherit;
        padding: 0;
      }

      :focus-visible {
        outline-offset: -2px;
      }

      li.module-list-item {
        --spacing: 1rem;
        --icon-size: 4rem;
        display: block;

        > a {
          background-color: var(--curriculum-bg-color-list-item-body);
          border: 1px solid var(--curriculum-border-color);
          border-radius: var(--elem-radius);
          box-shadow: var(--curriculum-shadow);
          display: flex;
          flex-direction: column;
          justify-self: center;
          max-width: 20rem;
          min-width: 15rem;
          overflow: auto;
          padding: 0;
          scroll-snap-align: center;
          text-decoration: none;
          width: 100%;

          &:hover {
            border-color: var(--curriculum-border-color-hover);
            text-decoration: none;
          }

          @media (min-width: $screen-md) {
            min-width: initial;
          }

          > header {
            align-items: center;
            background-color: var(--curriculum-bg-color-list-item-header);
            display: flex;
            flex-direction: column;
            font-weight: var(--font-body-strong-weight);
            height: 10.5rem;
            height: calc(3 * var(--spacing) + var(--icon-size) + 2lh);
            padding: var(--spacing);
            row-gap: var(--spacing);

            svg.topic-icon {
              height: var(--icon-size);
              width: var(--icon-size);

              circle {
                fill: var(--curriculum-bg-color-list-item-icon);
              }

              path {
                fill: var(--curriculum-color-list-item-icon);
              }
            }

            > span {
              color: var(--text-primary);
              margin: 0 auto;
              text-align: center;
            }
          }

          > section {
            align-items: center;
            display: flex;
            flex-direction: column;
            font-size: var(--type-smaller-font-size);
            height: 11rem;
            justify-content: space-between;
            padding: var(--spacing);

            p {
              color: var(--text-secondary);
              margin: 0;
              text-align: center;
            }

            p:last-child {
              color: var(--curriculum-color-topic);
              font-weight: 600;
            }
          }
        }
      }

      @media (min-width: $screen-sm) {
        grid-template-columns: 1fr 1fr;
      }

      @media (min-width: $screen-xxl) {
        grid-template-columns: 1fr 1fr 1fr;
      }
    }
  }
}

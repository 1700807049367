.notecard {
  --note-background: var(--background-information);
  --note-theme: var(--icon-information);
  background-color: var(--note-background);
  border-left: 2px solid var(--note-theme);
  border-radius: var(--elem-radius);
  box-shadow: var(--shadow-01);
  margin: 1rem 0;
  padding: 1rem 1rem 1rem 3rem;
  position: relative;

  &,
  p,
  li {
    color: var(--text-secondary);
  }

  p {
    line-height: 2;
    margin: 0;
  }

  &:before {
    background-color: var(--note-theme);
    content: "";
    display: block;
    height: 1rem;
    left: 1rem;
    mask-image: url("../../../assets/icons/note-info.svg");
    mask-position: center;
    mask-repeat: no-repeat;
    mask-size: contain;
    position: absolute;
    top: 1.35rem;
    width: 1rem;
  }

  &.inline {
    font: var(--type-body-s);
    margin: 0.5rem;
    padding: 0.125rem 0.375rem 0.125rem 1.5rem;
  }

  &.inline::before {
    display: block;
    height: 0.8rem;
    left: 0.2rem;
    top: 0.1875rem;
    width: 0.8rem;
  }

  &.warning {
    --note-background: var(--background-warning);
    --note-theme: var(--icon-warning);

    &:before {
      mask-image: url("../../../assets/icons/note-warning.svg");
    }
  }

  &.nonstandard {
    --note-background: var(--background-warning);
    --note-theme: var(--icon-warning);

    &:before {
      mask-image: url("../../../assets/icons/nonstandard.svg");
    }
  }

  &.error,
  &.negative {
    --note-background: var(--background-critical);
    --note-theme: var(--icon-critical);

    &:before {
      mask-image: url("../../../assets/icons/note-deprecated.svg");
    }
  }

  &.deprecated {
    --note-background: var(--background-critical);
    --note-theme: var(--icon-critical);

    &:before {
      mask-image: url("../../../assets/icons/deprecated.svg");
    }
  }

  &.experimental {
    &:before {
      mask-image: url("../../../assets/icons/experimental.svg");
    }
  }

  &.success {
    --note-background: var(--background-success);
    --note-theme: var(--icon-success);

    &:before {
      mask-image: url("../../../assets/icons/note-info.svg");
    }
  }

  // extra classes added to fix specificity.

  &,
  .main-page-content &,
  dd & {
    ul,
    ol {
      padding-left: 1rem;
    }

    ul,
    ol,
    li,
    p {
      margin: 0;
      padding-bottom: 0.5rem;

      &:last-child {
        padding-bottom: 0;
      }
    }
  }

  &:last-of-type {
    margin-bottom: 2rem;
  }

  &:first-of-type {
    margin-top: 2rem;
  }

  > *:first-child {
    margin-top: 0;
  }

  > *:last-child *:last-child {
    margin-bottom: 0;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: var(--text-primary);
    font: var(--type-base-font-size-rem);
    margin-bottom: 0.5rem;
  }
}

.main-content {
  .notecard {
    a:not(.button),
    code {
      color: var(--notecard-link-color);

      &:hover {
        text-decoration: none;
      }
    }
  }
}

@use "../ui/vars" as *;

.interactive {
  background-color: var(--background-secondary);
  border: none;
  border-radius: var(--elem-radius);
  color: var(--text-primary);

  // Since heights are now responsive, these classes are added
  // in the EmbedInteractiveExample.ejs macro.
  height: 675px;
  margin: 1rem 0;
  padding: 0;
  width: 100%;

  &.is-js-height,
  &.is-taller-height,
  &.is-shorter-height {
    border: 0 none;
  }

  &.is-js-height {
    height: 513px;
  }

  &.is-shorter-height {
    height: 433px;
  }

  &.is-taller-height {
    height: 725px;
  }

  &.is-tabbed-shorter-height {
    height: 487px;
  }

  &.is-tabbed-standard-height {
    height: 548px;
  }

  &.is-tabbed-taller-height {
    height: 774px;
  }
}

// The layout switches at 590px in the `mdn/bob` app.
// In order to respect the height shifts without using
// JS, a complicated media query is needed. This is
// fragile, as if the margins or anything changes
// on the main layout, this will need to be adjusted.

// This spans from the time the iframe is 590px
// wide in the mobile layout to the time it switches
// to two columns. Then, from the time the iframe
// is 590px wide in the two-column layout on up.
@media (min-width: 688px) and (max-width: $screen-md - 1), (min-width: 1008px) {
  .interactive {
    height: 375px;

    &.is-js-height {
      height: 444px;
    }

    &.is-shorter-height {
      height: 364px;
    }

    &.is-taller-height {
      height: 654px;
    }

    &.is-tabbed-shorter-height {
      height: 351px;
    }

    &.is-tabbed-standard-height {
      height: 421px;
    }

    &.is-tabbed-taller-height {
      height: 631px;
    }
  }
}

@font-face {
  font-display: swap;
  font-family: "Inter";
  font-stretch: 75% 100%;
  font-style: oblique 0deg 20deg;
  font-weight: 1 999;
  src:
    url("../../assets/fonts/Inter.var.woff2")
      format("woff2 supports variations"),
    url("../../assets/fonts/Inter.var.woff2") format("woff2-variations");
}

@use "../../vars" as *;

.logo {
  align-items: center;
  display: flex;
  // Width of largest logo ("mdn web docs").
  width: 160px;

  svg {
    height: 1.5rem;
    margin-top: 0.3rem;
  }

  .logo-m,
  .logo-_ {
    fill: var(--text-link);
  }

  .logo-text {
    fill: var(--text-primary);
  }
}

.contributor-spotlight-content-container {
  margin: 3rem auto;
  max-width: 52rem;
  padding: 0 1rem;

  li {
    line-height: 1.5;
    list-style-type: disc;
    margin-bottom: 1rem;
  }

  ul {
    padding-left: 1rem;
  }

  .quote {
    background-color: var(--category-color-background);
    color: var(--text-primary);

    .icon {
      background-color: var(--text-primary);
    }
  }

  .profile-image {
    border-radius: 50%;
    height: 200px;
    width: 200px;
  }

  .profile-header {
    align-items: center;
    border-radius: var(--elem-radius);
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
    width: 100%;

    h2 {
      color: var(--text-primary);
      margin-bottom: 0;
      margin-top: 0;
    }

    .username {
      background-color: var(--category-color);
      color: var(--text-invert);
      font-size: 1.75rem;
      padding: 0.2rem 0.4rem;
    }
  }

  h2 {
    a {
      color: var(--text-primary);
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

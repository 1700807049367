@use "../../ui/vars" as *;

.contributor-spotlight {
  align-items: center;
  color: var(--text-primary);
  display: flex;
  flex-direction: column;
  padding: 2rem 1rem;
  position: relative;
  width: 100%;

  blockquote {
    border: 0;
    display: flex;
    font-style: italic;
    font-variation-settings: "slnt" -10;
    margin: 0;
    padding: 0;

    .icon {
      margin-right: 0.5rem;
      margin-top: 0.25rem;
    }
  }

  h4 {
    font-family: var(--font-body);
    font-size: var(--type-smaller-font-size);
  }

  .text-col {
    align-items: center;
    border-radius: 0.3rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    max-width: 52rem;
    width: 100%;

    h3 {
      font-size: var(--type-heading-h5-font-size);
    }
  }

  .spotlight-cta {
    --button-bg: var(--text-link);
    --button-border-color: #{$mdn-color-neutral-90};
    --button-color: var(--mdn-color-black);
    --button-radius: 10rem;
    --button-padding: 1rem;
    --button-primary-hover: #{$mdn-color-neutral-60};
    width: fit-content;
  }

  .wrapper {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    max-width: 52rem;
    padding: 2rem 1rem;
    width: 100%;
  }

  .contributor-graphic {
    align-items: center;
    display: flex;
    justify-content: center;
    width: 50%;
    word-break: break-word;

    img {
      height: max-content;
      max-width: 90%;
    }
  }

  .contributor-name {
    background-color: var(--mdn-color-white);
    color: var(--mdn-color-black);
    // note: this deviates from var(--type-heading-h4-font-size),
    // as it uses the body font family.
    font-size: 1.5rem;
    font-weight: 650;
    line-height: 1.4;
    text-transform: uppercase;
    width: fit-content;

    &::after,
    &::before {
      content: " ";
    }

    &:link,
    &:visited {
      color: var(--mdn-color-black);
      text-decoration: none;
    }

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }

  @media (min-width: $screen-md) {
    .wrapper {
      align-items: initial;
      flex-direction: row;
    }

    .text-col {
      align-items: initial;
      text-align: left;
    }
  }

  .mandala-container {
    --mandala-primary: var(--border-primary);
    background: rgba(1, 1, 1, 0.9);
    height: 100%;
    overflow: hidden;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;

    .mandala-translate {
      transform: translate(-5rem, -0);
    }
  }
}

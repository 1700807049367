interactive-example {
  display: block;
  height: 444px;
  margin: 1rem 0;

  &[height="shorter"] {
    height: 364px;
  }

  &[height="taller"] {
    height: 654px;
  }
}

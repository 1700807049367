@use "sass:math";

@use "../../../ui/vars" as *;

.document-toc {
  margin-bottom: 2rem;
  padding: 0;

  @media (max-width: $screen-sm) {
    padding: 0 1rem;
  }

  // double classes to override specificity from root styles
  &-heading.document-toc-heading {
    font: var(--type-heading-h5);
    letter-spacing: 1.5px;
    margin: 0 0 1rem;
  }

  // double classes to override specificity from root styles
  &-list.document-toc-list {
    font-size: var(--type-smaller-font-size);
    list-style: none;
    padding-left: 0;

    li {
      margin: 0;
    }
  }

  // double classes to override specificity from root styles
  &-link.document-toc-link:not(.button) {
    border-left: 2px solid var(--border-secondary);
    color: var(--text-secondary);
    display: inline-block;
    padding: 0.5rem 1rem;
    text-decoration: none;

    &:hover {
      color: var(--text-link);
    }

    &[aria-current]:not([aria-current=""]):not([aria-current="false"]) {
      background-color: var(--background-toc-active);
      border-bottom-right-radius: 0.25rem;
      border-left: 2px solid var(--category-color);
      border-top-right-radius: 0.25rem;
      color: var(--text-primary);
      font-weight: 600;
    }
  }

  &-item-sub > .document-toc-link:not(.button) {
    padding-left: 2rem;
  }

  .show-toc {
    display: block;
  }
}

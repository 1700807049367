@use "../../../ui/vars" as *;

// Style for desktop.

@media (min-width: $screen-sm) {
  .bc-table {
    thead {
      display: table-header-group;

      .bc-platforms {
        th {
          vertical-align: revert;
        }
      }
    }

    td,
    th {
      background: inherit;
      padding: 0.25rem;
      width: 2rem;
    }

    td.bc-support {
      padding: 0;

      > button {
        padding: 0.25rem;
      }
    }

    tr.bc-history-desktop {
      display: table-row;
    }
  }

  .table-container {
    margin: 0 -3rem;
    overflow: auto;
    width: 100vw;
  }

  .table-container-inner {
    min-width: max-content;
    padding: 0 3rem;
    position: relative;

    &:after {
      bottom: 0;
      content: "";
      height: 10px;
      position: absolute;
      right: 0;
      width: 10px;
    }
  }

  .bc-support-level,
  .bc-browser-name {
    display: none;
  }

  .bc-notes-list {
    margin-left: 20%;
    width: auto;
  }

  .bc-support {
    .bc-support-level {
      display: none;
    }

    &[aria-expanded="true"] {
      position: relative;

      &:after {
        background: var(--text-primary);
        bottom: -1px;
        content: "";
        height: 2px;
        left: 0;
        position: absolute;
        width: 100%;
      }

      .bc-history-mobile {
        display: none;
      }
    }
  }

  .bc-has-history {
    cursor: pointer;

    &:hover {
      background: var(--background-secondary);
    }
  }
}

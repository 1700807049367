.top-level-entry-container {
  &.active > a {
    &:link,
    &:visited {
      color: var(--text-active);
    }

    &:hover,
    &:active {
      color: var(--category-color);
    }
  }

  .top-level-entry-dot ~ .top-level-entry::after {
    background: var(--text-primary-blue);
    border: 1px solid var(--background-primary);
    border-radius: 2rem;
    content: "";
    height: 0.5rem;
    position: absolute;
    right: 0;
    top: 0.5rem;
    width: 0.5rem;
  }
}

@use "../../../ui/vars" as *;

.mandala-container {
  // transform: skew(-7deg, 10deg);

  --mandala-primary: #{$mdn-color-neutral-70};
  --mandala-accent-1: var(--html-accent-color);
  --mandala-accent-2: var(--http-accent-color);
  --mandala-accent-3: var(--js-accent-color);
  --mandala-accent-4: #{$mdn-color-neutral-30};
  display: flex;
  justify-content: center;

  svg {
    font-size: 1.5rem;
    font-weight: 300;
    user-select: none;
  }

  svg > text {
    fill: var(--mandala-primary);
  }

  textPath[href="#circle1"] {
    font-size: 1.5rem;
  }

  textPath[href="#circle2"] {
    font-size: 1.3rem;
  }

  textPath[href="#circle3"] {
    font-size: 1.2rem;
  }

  textPath[href="#circle4"] {
    font-size: 1.1rem;
  }

  textPath[href="#circle5"] {
    font-size: 1rem;
  }
}

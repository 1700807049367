@mixin _button {
  --button-font: var(--type-emphasis-m);
  --button-padding: 0.43rem 1rem;
  --button-radius: var(--elem-radius, 0.25rem);

  background-color: var(--button-bg);
  border: 1px solid var(--button-border-color);
  border-radius: var(--button-radius);
  color: var(--button-color);
  display: inline-block;
  font: var(--button-font);
  letter-spacing: normal;
  padding: var(--button-padding);
  text-align: center;
  text-decoration: none;

  &.external:after {
    display: none;
  }

  &:hover {
    --button-border-color: var(--button-bg-hover);
    --button-bg: var(--button-bg-hover);
  }

  &:active {
    --button-bg: var(--button-bg-active);
  }
}

@mixin primary {
  --button-bg: var(--button-primary-default);
  --button-bg-hover: var(--button-primary-hover);
  --button-bg-active: var(--button-primary-active);
  --button-border-color: var(--button-primary-default);
  --button-color: var(--background-primary);

  @include _button;
}

@mixin secondary {
  --button-bg: var(--button-secondary-default);
  --button-bg-hover: var(--button-secondary-hover);
  --button-bg-active: var(--button-secondary-active);
  --button-border-color: var(--border-primary);
  --button-color: var(--text-secondary);

  @include _button;
}

@use "../ui/vars" as *;
@use "../ui/atoms/button/mixins" as button;

@mixin theme-setup {
  .light & {
    @content (light);
  }

  .dark & {
    @content (dark);
  }

  // OS Default.
  :root:not(.light):not(.dark) & {
    @media (prefers-color-scheme: light) {
      @content (light);
    }

    @media (prefers-color-scheme: dark) {
      @content (dark);
    }
  }
}

@mixin layout {
  h2,
  h3,
  p {
    margin: 0;
  }

  h2,
  h3 {
    color: var(--layout-text-primary);

    a {
      color: unset;
      text-decoration: none;
    }
  }

  a {
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }

  p + p {
    margin-top: 1.5rem;
  }

  section {
    margin-left: auto;
    margin-right: auto;
    max-width: var(--max-width);
    padding-left: var(--gutter);
    padding-right: var(--gutter);
    width: 100%;
  }

  h2 {
    font-size: 2rem;
    font-weight: 600;
    margin-bottom: 1rem;

    @media (max-width: $screen-md) {
      font-size: 1.375rem;
    }
  }
}

@mixin header {
  background: linear-gradient(
    to top,
    var(--header-next-section-bg, transparent) 0%,
    var(--header-next-section-bg, transparent)
      calc(var(--header-stats-height) / 2),
    var(--header-bg) calc(var(--header-stats-height) / 2),
    var(--header-bg) 100%
  );

  @media (max-width: $screen-md) {
    padding-top: 1rem;
  }

  section {
    padding-top: 5rem;

    @media (max-width: $screen-md) {
      padding-top: 0;
    }
  }

  h1 {
    color: var(--header-text-primary);
    font-size: 2.5rem;
    margin-bottom: 1rem;

    @media (max-width: $screen-md) {
      font-size: 2rem;
    }
  }

  p {
    color: var(--header-text-secondary);
    margin-bottom: 1.5rem;
  }

  + section {
    margin-top: 4.56rem;

    @media (max-width: $screen-md) {
      margin-top: 2rem;
    }
  }
}

@mixin stats {
  background: var(--stats-bg);
  border-radius: 0.5rem;
  box-shadow: var(--stats-box-shadow);
  color: var(--stats-text-primary);
  display: flex;
  gap: 1rem;
  justify-content: space-around;
  margin-top: 5em;
  padding: 1rem;
  position: relative;
  text-align: center;
  z-index: 2;

  @media (max-width: $screen-md) {
    flex-wrap: wrap;
    margin-top: 2rem;
  }

  li {
    align-items: baseline;
    column-gap: 1rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    min-width: 7.75rem;
    overflow-wrap: anywhere;

    @media (max-width: $screen-md) {
      align-items: center;
      flex: 1;
      flex-direction: column;
      justify-content: flex-start;
    }

    strong {
      align-items: center;
      background: var(--stats-stat-bg);
      border-radius: 50%;
      color: var(--stats-stat-text);
      display: inline-flex;
      height: 3.75rem;
      justify-content: center;
      width: 3.75rem;
    }
  }
}

@mixin section {
  column-gap: min(5rem, 5vw);
  display: grid;
  grid-template-columns: 4fr 6fr;

  @media (max-width: $screen-md) {
    display: block;
  }

  > * {
    min-width: 0;
  }
}

@mixin boxes {
  display: grid;
  gap: 2rem;
  grid-auto-rows: 1fr;
  grid-template-columns: repeat(auto-fill, minmax(16rem, 1fr));

  li {
    align-items: center;
    background: var(--boxes-bg);
    border: 1px solid var(--boxes-border);
    border-radius: 0.5rem;
    box-shadow: var(--boxes-shadow);
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    justify-content: space-between;
    padding: 1.5rem;
    text-align: center;

    h3,
    h4,
    h5,
    h6 {
      align-self: stretch;
      background: var(--boxes-header-bg);
      border-radius: 0.5rem 0.5rem 0 0;
      color: var(--boxes-header-color);
      font-size: 1.25rem;
      font-weight: 500;
      margin: -1.5rem;
      margin-bottom: 0;
      padding: 1.5rem;
    }

    p {
      color: var(--boxes-main-color);
      margin: 0;
    }
  }
}

@mixin stairs {
  --stairs-icon-size: 3.125rem;
  --stairs-step-indent: 3.125rem;
  --stairs-step-gap: 4rem;

  li {
    --stairs-padding-left: calc(var(--stairs-step-indent) * var(--nth-child));
    align-items: center;
    display: flex;
    gap: 1rem;
    padding-left: var(--stairs-padding-left);
    position: relative;

    @media (max-width: $screen-md) {
      --stairs-step-indent: 0;
      --stairs-padding-left: var(--stairs-icon-size);
      --stairs-step-gap: 1rem;
    }

    &:not(:last-of-type) {
      margin-bottom: var(--stairs-step-gap);
    }

    &::before {
      background: var(--stairs-color);
      content: "";
      display: block;
      flex-shrink: 0;
      height: var(--stairs-icon-size);
      margin-left: calc(-1 * var(--stairs-icon-size));
      mask-image: var(--stairs-icon);
      mask-position: center;
      mask-repeat: no-repeat;
      mask-size: 80%;
      width: var(--stairs-icon-size);
    }

    &:not(:last-of-type)::after {
      --height: calc(var(--stairs-step-gap) * 1.2);
      background: linear-gradient(to bottom, var(--stairs-color), transparent);
      bottom: calc(-1 * var(--height));
      content: "";
      display: block;
      height: var(--height);
      left: calc(var(--stairs-padding-left) - 50px);
      mask: url("../assets/lines.svg");
      mask-position: center;
      mask-repeat: no-repeat;
      mask-size: contain;
      position: absolute;
      width: calc(var(--stairs-icon-size) + var(--stairs-step-indent));
    }
  }

  @for $i from 1 through 4 {
    li:nth-child(#{$i}) {
      --nth-child: #{$i};
    }
  }
}

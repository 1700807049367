.section-newsletter {
  margin: 0 auto;
  max-width: 35rem;
  padding: 2rem;

  input[type="email"],
  button {
    width: 100%;
  }

  form {
    margin-bottom: 2rem;
  }
}

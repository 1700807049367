@use "../../ui/vars" as *;
@use "../../ui/mixins" as *;

.featured-articles {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 0 1rem;

  h2 {
    font: var(--type-heading-h4);
    margin-top: 0;
  }

  .tile-container {
    display: grid;
    gap: 1rem;
    grid-auto-flow: row;
    max-width: 52rem;

    @media (min-width: $screen-md) {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  .article-tile {
    border: 1px solid var(--border-primary);
    border-radius: 0.5rem;
    display: flex;
    flex-direction: column;
    padding: 1rem;

    &:hover {
      box-shadow: var(--shadow-02);
    }

    .tile-tag {
      color: var(--text-primary);
      font-weight: 500;

      &:hover {
        text-decoration: underline;
      }
    }

    .tile-title {
      align-items: flex-start;
      display: flex;
      flex-grow: 1;
      font: var(--type-heading-h5);
      font-weight: 500;
      line-height: var(--intermediate-line-height);
      margin: 0;
      margin-bottom: 1rem;

      a {
        color: var(--text-primary);
      }
    }

    p {
      font: var(--type-smaller-font-size);
      line-height: var(--base-line-height);
      margin: 0;
      @include line-clamp(
        3,
        var(--base-line-height),
        var(--background-primary)
      );

      @media (min-width: $screen-md) {
        min-height: calc(3em * var(--base-line-height));
      }
    }
  }
}

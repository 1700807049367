.get-involved {
  background-color: var(--mdn-background-dark);

  section {
    color: var(--text-primary);
    display: flex;
    flex-direction: column;
    gap: 1rem;
    line-height: 1.75;
    margin: 0 auto;
    max-width: 52rem;

    padding: 2rem 1rem;

    h2 {
      font-family: var(--font-heading);
      font-size: 1.3rem;
      font-weight: 600;
      line-height: 120%;
      margin: 0;
    }

    .get-involved-cta {
      color: var(--category-color);
      display: block;
      margin-top: 1rem;
    }
  }
}
